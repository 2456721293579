<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template
          #default
        >
          <tbody>
            <tr>
              <td>
                <v-btn
                  icon
                  color="white"
                >
                  <v-icon
                    :color="PREGUNTA_CHECKLIST.colorResultado.ok"
                  >
                    {{ $vuetify.icons.values.preguntaChecklistOk }}
                  </v-icon>
                </v-btn>
              </td>
              <td>
                Pregunta correcta
              </td>
            </tr>
            <tr>
              <td>
                <v-btn
                  icon
                  color="white"
                >
                  <v-icon
                    :color="PREGUNTA_CHECKLIST.colorResultado.conAnomalias"
                  >
                    {{ $vuetify.icons.values.preguntaChecklistMal }}
                  </v-icon>
                </v-btn>
              </td>
              <td>
                Pregunta con una anomalía
              </td>
            </tr>
            <tr>
              <td>
                <v-badge
                  overlap
                  color="red"
                  content="3"
                >
                  <v-btn
                    icon
                    color="white"
                  >
                    <v-icon
                      :color="PREGUNTA_CHECKLIST.colorResultado.conAnomalias"
                    >
                      {{ $vuetify.icons.values.preguntaChecklistMal }}
                    </v-icon>
                  </v-btn>
                </v-badge>
              </td>
              <td>
                Pregunta con múltiples anomalías
              </td>
            </tr>
            <tr>
              <td>
                <v-btn
                  icon
                  color="white"
                >
                  <v-icon
                    :color="PREGUNTA_CHECKLIST.colorResultado.noProcede"
                  >
                    {{ $vuetify.icons.values.preguntaChecklistNoProcede }}
                  </v-icon>
                </v-btn>
              </td>
              <td>
                Pregunta que no procede
              </td>
            </tr>
            <tr>
              <td>
                <v-btn
                  icon
                  color="white"
                >
                  <v-icon
                    :color="PREGUNTA_CHECKLIST.colorResultado.conAnomalias"
                  >
                    {{ $vuetify.icons.values.preguntaChecklistDesconocido }}
                  </v-icon>
                </v-btn>
              </td>
              <td>
                Pregunta desconocida
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </b10-legend>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import { PREGUNTA_CHECKLIST } from '@/utils/consts'

export default {
  mixins: [internalShowingMixin],
  data () {
    return {
      PREGUNTA_CHECKLIST,
    }
  },
}
</script>
